import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top" style={{ backgroundColor: '#032654' }}>
      <div className="container px-4 px-lg-5">
        <Link to="/">
          <img src="img/LOGO-SISENT-TRANSPARANT-BLANC.png" alt="Logo" className="navbar-brand" style={{ width: '150px', height: 'auto' }} />
        </Link>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto py-4 py-lg-0">
            <li className="nav-item"><a className="nav-link px-lg-3 py-3 py-lg-4 fs-5" href="#services"> Expertises</a></li>
            <li className="nav-item"><a className="nav-link px-lg-3 py-3 py-lg-4 fs-5" href="#contact"> Contact</a></li>
          </ul>
        </div>
      </div>
    </nav>  
  );
};

export default Navbar;
