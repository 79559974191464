import React from 'react';
import ReactGA from 'react-ga';
import Header from './pages/basic/Header';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home'; 
import NotFound from './pages/NotFound';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/remixicon/remixicon.css';

import './assets/css/style.css'
import './assets/css/footer.css'
import './assets/css/header.css'
import './assets/css/navbar.css'
import './assets/css/mobile.css'
import './assets/css/home-header.css'
import './assets/css/services.css'
import './assets/css/notfound.css'

const App = () => {
  React.useEffect(() => {
    initializeReactGA();
  }, []);

  const initializeReactGA = () => {
    ReactGA.initialize('YOUR_GA_TRACKING_ID');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <Router>
      <Header />
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
