import React, { useState } from 'react';
import Footer from './basic/Footer';
import Navbar from './basic/Navbar';
import { Form, Button, Row, Col } from 'react-bootstrap';
import fetch from 'node-fetch';

function Home () {
    
  const [formData, setFormData] = useState({
    name: '',
    first_name: '',
    entreprise: '',
    email: '',
    telephone: '',
    message: ''
  });
 
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const phoneRegex = /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = 'Veuillez entrer votre nom';
    }
    if (!formData.first_name) {
      errors.first_name = 'Veuillez entrer votre prénom';
    }
    if (!formData.email) {
      errors.email = 'Veuillez entrer votre adresse email';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Veuillez entrer une adresse email valide';
    }
    if (!formData.message) {
      errors.message = 'Veuillez entrer un message';
    } else if (formData.message.length < 15 || formData.message.length > 500) {
      errors.message = 'Le message doit contenir entre 15 et 500 caractères';
    }
    if (!formData.telephone) {
      errors.telephone = 'Veuillez entrer votre numéro de téléphone';
    } else if (!phoneRegex.test(formData.telephone)) {
      errors.telephone = 'Veuillez entrer un numéro de téléphone valide';
    }  
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setSubmitting(true);
  
      // Création de l'objet à envoyer
      const formDataToSend = {
        subject: '[SISENT][CONSULTING][CONTACT]',
        last_name: formData.name,
        content: formData.message,
        first_name: formData.first_name,
        tel: formData.telephone,
        mail: formData.email,
        entreprise: formData.entreprise,
    // Ajoutez d'autres champs si nécessaire
      };
  
      fetch('https://mail.sisent.org/api/mail/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formDataToSend)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Erreur lors de la requête');
          }
          return response.json();
        })
        .then(data => {
          // Traitement de la réponse si nécessaire
          setShowSuccessMessage(true); // Afficher le message de succès
          setSubmitting(false);
          // Réinitialiser le formulaire si nécessaire
          setFormData({
            name: '',
            first_name: '',
            entreprise: '',
            email: '',
            telephone: '',
            message: ''
          });
        })
        .catch(error => {
          // Gestion des erreurs
          console.error('Erreur lors de la requête :', error);
          setSubmitting(false);
          // Gérer l'erreur si nécessaire
        });
    }
  };
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  
  return (

    <>
      <Navbar/>
      <section id="home-header" className="d-flex align-items-center">

        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
              <h1>
                Laissez nos experts vous accompagner vers la réussite de votre projet.
              </h1>
              <br></br>
              <h2>
                DevSecOps, Infrastructure, Cloud, Agilité, Développeur FullStack, Big Data, Monitoring, Cybersecurité et plus..
              </h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
              <a className="btn-get-started scrollto" href="#contact">
                Contactez nous !
              </a>
                {/*<a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>*/}
              </div>
            </div>
            <div className="col-lg-3 order-1 order-lg-2 pricing" data-aos="zoom-in" data-aos-delay="200">
            </div>     
          </div>
        </div>

      </section>

    <main className="mb-4">

        <section id="services" className="services">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>Expertises</h2>
              <p>SISENT est une entreprise d'expert en accompagnement et conseil digital. En tant qu'experts, nous intervenons dans un large spectre de domaines: Cloud, DevOps, Data, jusqu'au développement Full Stack. Notre approche personnalisée et notre expertise pointue nous permettent de répondre aux besoins spécifiques de chaque client, en les accompagnant de manière proactive dans leur transition vers un environnement digital performant et sécurisé.</p>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box">
                  <div className="icon"><i className="bi bi-infinity"></i></div>
                  <h4><a href="/">DevSecOps</a></h4>
                  <p>Notre équipe de DevSecOps intègre les meilleures pratiques de sécurité dès les premières phases du développement, en combinant expertise en développement et en opérations. En utilisant des pipelines CI/CD (Continuous Integration/Continuous Deployment), nous assurons une livraison continue, rapide et sécurisée des applications, garantissant ainsi la protection des données et la résilience des systèmes tout au long du cycle de vie du logiciel.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                <div className="icon-box">
                  <div className="icon"><i className="bi bi-file-code"></i></div>
                  <h4><a href="/">Dev FullStack</a></h4>
                  <p>Avec notre équipe d'experts en développement FullStack, nous maîtrisons tant les aspects front-end que back-end, assurant ainsi la création d'applications robustes et évolutives. Cette combinaison de compétences nous permet de concevoir des solutions logicielles complètes, répondant aux besoins les plus complexes des entreprises tout en offrant une expérience utilisateur exceptionnelle.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box">
                  <div className="icon"><i className="bi bi-database"></i></div>
                  <h4><a href="/">Data</a></h4>
                  <p>La gestion des données avec une infrastructure robuste garantit une résilience et une sécurité optimales, assurant la protection et la disponibilité des informations critiques pour les entreprises. Dans le domaine du big data, cette approche renforce la capacité à analyser des volumes massifs de données, offrant ainsi des insights précieux pour des décisions stratégiques.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                <div className="icon-box">
                  <div className="icon"><i className="bi bi-cloud-upload"></i></div>
                  <h4><a href="/">Cloud</a></h4>
                  <p>Les services gérés dans le cloud offrent une gestion simplifiée et évolutive des infrastructures informatiques, libérant ainsi les entreprises des contraintes opérationnelles. Le cloud permet aux entreprises de bénéficier d'une flexibilité accrue, d'une réduction des coûts et d'une accessibilité optimale aux ressources informatiques.</p>
                </div>
              </div>

            </div>
            
          </div>
        </section>
        
        <section id="contact" className="contact section-bg">
        <div className="container">
          <div className="row">
            <div className="col"></div>
            <div className="col-6">
              <center>
                <h1>Contact</h1>
              <h5>Travaillons ensemble ! Dites-nous en plus sur vous</h5>
              </center>
              {showSuccessMessage && (
                <div className="alert alert-success" role="alert">
                  Votre mail a bien été envoyé.
                </div>
              )}

              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="name">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control type="text" value={formData.name} onChange={handleChange} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="first_name">
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control type="text" value={formData.first_name} onChange={handleChange} isInvalid={!!errors.first_name} />
                    <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="email">
                    <Form.Label>Adresse Email</Form.Label>
                    <Form.Control type="email" value={formData.email} onChange={handleChange} isInvalid={!!errors.email} />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="telephone">
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control type="tel" value={formData.telephone} onChange={handleChange} isInvalid={!!errors.telephone} />
                    <Form.Control.Feedback type="invalid">{errors.telephone}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="entreprise">
                  <Form.Label>Entreprise</Form.Label>
                  <Form.Control type="text" value={formData.entreprise} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={5} value={formData.message} onChange={handleChange} isInvalid={!!errors.message} />
                  <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                </Form.Group>
                <center>
                  <Button className="buy-btn" type="submit" disabled={submitting}>
                    {submitting ? 'Envoi en cours...' : 'Envoyer'}
                  </Button>
                </center>
              </Form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </section>

    </main>
    <Footer />

    </>
  );
}

export default Home;
