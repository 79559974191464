import React from 'react';

const Footer = () => {
  return (
    <footer id="footer">

        <div className="footer-top">
            <div className="container">
                <div className="row">

                    <div className="col-lg-3 col-md-6 footer-contact">
                        <h3>SISENT</h3>
                        <p>
                        3 Rue Sancho Panca <br />
                        93160 Noisy-Le-Grand<br />
                        France <br /><br />
                        <strong>Email:</strong> contact@sisent.fr<br />
                        </p>
                    </div>

                    <div className="col-lg-3 col-md-6 footer-links">
                        <h4>Liens utiles</h4>
                        <ul>
                        <li><i className="bx bx-chevron-right"></i> <a href="/">Home</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="#services">Expertises</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="#contact">Contact</a></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-6 footer-links">
                    </div>

                    <div className="col-lg-3 col-md-6 footer-links">
                        <h4>Nos réseaux sociaux</h4>
                        <p>Restez connectés et suivez-nous sur nos réseaux sociaux pour ne rien manquer de nos actualités !</p>
                        <div className="social-links mt-3">
                            <a href="https://www.linkedin.com/company/sisent" target="_blank" rel="noreferrer" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        &copy; Copyright <strong><span>SISENT</span></strong>. All Rights Reserved
                    </div>

                </div>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
